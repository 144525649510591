import { Container, Navbar, Dropdown } from "react-bootstrap";

import { AiOutlineLogout } from "react-icons/ai";
import profilePlaceHolder from "../../assets/images/default-avatar.jpg";
import WebService from "../../Services/WebService";

const Header = () => {
  return (
    <>
      <header className="site-header">
        <Container fluid className="header-nav">
          <Navbar expand="lg">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <div className="d-flex align-items-center ms-auto gap-3">
              <Dropdown className="profile-dd" autoClose={true}>
                <Dropdown.Toggle>
                  <div className="d-flex gap-3 justify-content-center align-items-center">
                    <div>
                      <img
                        src={profilePlaceHolder}
                        width={35}
                        height={35}
                        className=" rounded-circle object-fit-cover"
                        alt=""
                      />
                    </div>
                    <div className="">
                      <p className="font-15 mb-0 text-dark font-medium">
                        
                      <AiOutlineLogout size={22} className="me-1 align-text-top" title="Logout" onClick={()=>{
                        WebService.logout()
                      }}  />{" "}
                      </p>
                      
                    </div>
                  </div>
                  {/* <GoChevronDown size={16} className="icon" /> */}
                </Dropdown.Toggle>
                {/* <Dropdown.Menu align="end">
                  <Link to="/settings" className="dropdown-item">
                    <IoSettingsOutline
                      size={18}
                      className="me-1 align-text-top"
                    />{" "}
                    Setting
                  </Link>
                  <Link to="/subscription" className="dropdown-item">
                    <PiCertificate size={18} className="me-1 align-text-top" />{" "}
                    Subscription
                  </Link>
                  <Link
                    to="/login"
                    // onClick={() =>{dispatch(profileSettings({}));; WebService.logout();}}
                    className="dropdown-item"
                  >
                    <BiLogOutCircle size={18} className="me-1 align-text-top" />{" "}
                    Logout
                  </Link>
                </Dropdown.Menu> */}
              </Dropdown>
            </div>
          </Navbar>
        </Container>
      </header>
    </>
  );
};
export default Header;
