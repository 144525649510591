import {
  Routes,
  Route,
  Navigate,
  RouteProps,
  RoutesProps,
  useNavigate,
} from "react-router-dom";

import React, { Suspense, useEffect } from "react";
import { useState } from "react";
import ProtectedRoute from "./ProtectedRoute";
import Main from "../components/Main";
import { useSelector } from "react-redux";

const Navigation = () => {
  let navigate = useNavigate();

  interface ProtectedRouteProps extends RoutesProps {
    authenticationPath: string;
  }
  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
    authenticationPath: "/login",
  };
  const Login = React.lazy(() => import("../components/Login/Login"));
  const ForgotPassword = React.lazy(
    () => import("../components/Login/ForgotPassword")
  );
  const CustomerManagement = React.lazy(
    () => import("../components/ManageCustomers/CustomerList")
  );
  const QuestionaryManagement = React.lazy(
    () => import("../components/ManageQuestionary/QA")
  );
  const CustomerDetails = React.lazy(
    () => import("../components/ManageCustomers/CustomerDetails")
  );
  const ManagePayments = React.lazy(
    () => import("../components/ManagePayments/PaymentList")
  );
  const ManageSubscriptions = React.lazy(
    () => import("../components/ManageSubscription/SubscriptionList")
  );
  const AddSubscription = React.lazy(
    () => import("../components/ManageSubscription/AddSubscription")
  );

  const ManageRoles = React.lazy(
    () => import("../components/ManageRoles/RoleList")
  );
  const AddRole = React.lazy(() => import("../components/ManageRoles/AddRole"));
  const ManageUser = React.lazy(
    () => import("../components/ManageUsers/UserList")
  );
  const ManageCMS = React.lazy(() => import("../components/ManageCMS/CMSList"));
  const AddCMS = React.lazy(() => import("../components/ManageCMS/AddCMS"));
  const Settings = React.lazy(() => import("../components/Settings/Settings"));
  return (
    <>
      <div id="main-wraper">
        <Routes>
          <Route
            path="/login"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <Login />{" "}
              </Suspense>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <Suspense fallback={<></>}>
                {" "}
                <ForgotPassword />{" "}
              </Suspense>
            }
          />
          <Route
            path="/"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<Main />}
              />
            }
          >
            <Route
              path="/manage-customers"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <CustomerManagement />{" "}
                </Suspense>
              }
            />
            <Route
              path="/customer-details/:customerId"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <CustomerDetails />{" "}
                </Suspense>
              }
            />
            <Route
              path="/"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <CustomerManagement />{" "}
                </Suspense>
              }
            />
            <Route
              path="/manage-questionary"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <QuestionaryManagement />{" "}
                </Suspense>
              }
            />
            <Route
              path="/manage-payments"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <ManagePayments />{" "}
                </Suspense>
              }
            />

            <Route
              path="/manage-subscription"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <ManageSubscriptions />{" "}
                </Suspense>
              }
            />
            <Route
              path="/add-subscription"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <AddSubscription />{" "}
                </Suspense>
              }
            />
            <Route
              path="/edit-subscription/:id"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <AddSubscription />{" "}
                </Suspense>
              }
            />
            <Route
              path="/view-subscription/:id"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <AddSubscription />{" "}
                </Suspense>
              }
            />

            <Route
              path="/manage-roles"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <ManageRoles />{" "}
                </Suspense>
              }
            />
            <Route
              path="/add-role"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <AddRole />{" "}
                </Suspense>
              }
            />
            <Route
              path="/edit-role/:id"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <AddRole />{" "}
                </Suspense>
              }
            />
            <Route
              path="/manage-users"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <ManageUser />{" "}
                </Suspense>
              }
            />
            <Route
              path="/manage-cms"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <ManageCMS />{" "}
                </Suspense>
              }
            />
             <Route
              path="/add-content"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <AddCMS />{" "}
                </Suspense>
              }
            />
             <Route
              path="/edit-content/:id"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <AddCMS />{" "}
                </Suspense>
              }
            />
             <Route
              path="/settings"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <Settings />{" "}
                </Suspense>
              }
            />
          </Route>
        </Routes>
      </div>
    </>
  );
};

export default Navigation;
